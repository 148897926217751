//@ts-ignore
import {
  Box,
  useFormik,
  LoadingButton,
  CustomAlert,
  InfoIcon,
  Typography,
  styled,
} from "@enerbit/base";
import { useState, useEffect } from "react";
import { scheduledVsExecuted } from "../../../../const/formSchemas";
import { optionsReports } from "../../../../const/reportJson";
import { FieldLabelI, FormLabelI } from "../../../../models/Interfaces";
import FormItem from "../FormItem";
import { scheduledVsExecutedValidation } from "../../../../const/validationSchema";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { getScheduledVsExecuted } from "../../../../store/thunks";
import {
  updateEmpty,
  updateHasError,
  updateInvalidRangeDate,
} from "../../../../store/slice/reports/reportsSlice";
import LinearProgressWithLabel from "../LinearProgressWithLabel";
import { validateDateRange } from "../../../../helpers/reports";
import { getMaxDaysOfMonth } from "../../../../helpers/getMaxDaysOfMonth";

interface Props {
  option: string;
}

const ReportProgramExecute = ({ option }: Props) => {
  const [formData, setFormData] = useState<FormLabelI>(scheduledVsExecuted);

  const dispatch = useDispatch<AppDispatch>();
  const isLoadingReports = useSelector(
    (state: RootState) => state.reports.isLoadingReports
  );
  const empty = useSelector((state: RootState) => state.reports.empty);
  const hasError = useSelector((state: RootState) => state.reports.hasError);
  const progress = useSelector(
    (state: RootState) => state.reports.downloadProgress
  );
  const invalidRangeDate = useSelector(
    (state: RootState) => state.reports.invalidRangeDate
  );
  const orderId = useSelector((state: RootState) => state.reports.currentId);

  const formik = useFormik({
    initialValues: formData,
    validationSchema: scheduledVsExecutedValidation,
    onSubmit: () => {
      formik.resetForm();
      handleSubmitForm();
    },
  });

  const resetForm = (): void => {
    if (optionsReports[option].fields.length > 0) {
      setFormData({
        since: "",
        until: "",
        state: "",
        status: "",
      });
      return;
    }
  };

  const handleSubmitForm = async () => {
    const { since, until } = formData;
    if (since && until) {
      const maxDays = getMaxDaysOfMonth(since);
      console.log(maxDays);
      const test = validateDateRange(since, until);
      console.log(test, maxDays);
      if (validateDateRange(since, until) > maxDays) {
        dispatch(updateInvalidRangeDate(true));
        return;
      }

      // const url = optionsReports[option].url;
      await dispatch(getScheduledVsExecuted({ data: formData }));
      resetForm();
    }
  };

  // useEffect(() => {
  //   const clean = async () => {
  //     await dispatch(getScheduledVsExecuted({ data: formData, isClose: true }));
  //   };
  //   return () => {
  //     clean();
  //   };
  // }, []);

  return (
    <>
      <Box
        component="form"
        onSubmit={
          (scheduledVsExecutedValidation
            ? formik.handleSubmit
            : (e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                handleSubmitForm();
              }) as any
        }
      >
        <FormContainer grid_columns={2}>
          {optionsReports[option].fields.map(
            (field: FieldLabelI, index: number) =>
              field.name != "email_recipient" ? (
                <FormItem
                  field={field}
                  key={index}
                  formData={formData}
                  setFormData={setFormData}
                  formik={formik}
                />
              ) : null
          )}
        </FormContainer>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoIcon sx={{ color: "#ED7004" }} />
          <Typography
            color="primary"
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              margin: "10px 0",
              marginLeft: "10px",
            }}
          >
            Recuerda que puedes generar y descargar reportes de hasta 2 meses
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InfoIcon sx={{ color: "#ED7004" }} />
          <Typography
            color="primary"
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              margin: "10px 0",
              marginLeft: "10px",
            }}
          >
            {
              "Recuerda que los datos del archivo están separados por punto y coma (;). Para verlos mejor te recomendamos abrir el archivo en Excel."
            }
          </Typography>
        </Box>
        {!isLoadingReports && (
          <LoadingButton
            color="secondary"
            variant="contained"
            fullWidth
            type="submit"
            sx={{ my: 2 }}
          >
            Generar y descargar reporte
          </LoadingButton>
        )}
      </Box>
      {hasError && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity="error"
            text={"Error generando el reporte. Intenta otra vez."}
            onClose={() => dispatch(updateHasError())}
          />
        </Box>
      )}
      {empty && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity="info"
            text={
              "No existen reportes para este rango de fechas. Intenta con otro."
            }
            onClose={() => dispatch(updateEmpty(false))}
          />
        </Box>
      )}
      {isLoadingReports && !empty && (
        <Box mt={2}>
          <LinearProgressWithLabel
            title={`Generando reportes...ID orden: ${orderId}`}
            sx={{ height: "15px", borderRadius: "10px" }}
            value={progress}
          />
        </Box>
      )}
      {invalidRangeDate && (
        <Box my={1}>
          <CustomAlert
            severity="info"
            text={"El rango de las fechas consultadas excede los dos meses."}
            onClose={() => dispatch(updateInvalidRangeDate(false))}
          />
        </Box>
      )}
    </>
  );
};

export default ReportProgramExecute;

type Grid = {
  grid_columns: number;
};

const FormContainer = styled("div")(({ grid_columns }: Grid) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
