import { api } from "@enerbit/base";
const getAuthInfo = () => {
  const authInfo: any = JSON.parse(localStorage.getItem("authInfo") ?? "");
  return authInfo;
};

const getRefreshToken = () => {
  const { refresh_token } = getAuthInfo();
  return refresh_token;
};

const getAcessToken = () => {
  if (getAuthInfo()) {
    const accessToken = getAuthInfo().access_token;
    return accessToken;
  }
};

const refreshToken = async (): Promise<string | null> => {
  try {
    const refresh = getRefreshToken();
    const body = {
      refresh_token: refresh,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const { data } = await api.post("/auth/token/refresh", body, {
      headers,
    });
    return data.access_token;
  } catch (error) {
    return null;
  }
};

export { refreshToken, getAcessToken, getRefreshToken, getAuthInfo };
