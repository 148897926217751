import { FormLabelI } from "../models/Interfaces";

export const reporteInstalacion = {
  since: "",
  until: "",
  measurements_last_month: "",
  email_recipient: "",
};

export const scheduledVsExecuted = {
  since: "",
  until: "",
  state: "",
  status: "",
};

export const historicoLecturas = {
  measurements_last_month: "",
  email_recipient: "",
};

export const frontierControlReports: FormLabelI = {
  since: "",
  until: "",
};

export const materialReports: FormLabelI = {
  since: "",
  until: "",
  state: "",
  material_used: "",
};

export const materialDiagnostics: FormLabelI = {
  since: "",
  until: "",
  lead: "",
};

export const materialDiagnosticsSolar: FormLabelI = {
  since: "",
  until: "",
  lead: "",
};
