//@ts-ignore
import { yup } from "@enerbit/base";

export const reporteInstalacionValidation = yup.object({
  since: yup.string().required("Este campo es requerido").nullable(),
  until: yup.string().required("Este campo es requerido").nullable(),
  measurements_last_month: yup
    .string()
    .required("Este campo es requerido")
    .nullable(),
});

export const scheduledVsExecutedValidation = yup.object({
  since: yup.string().required("Este campo es requerido").nullable(),
  until: yup.string().required("Este campo es requerido").nullable(),
  state: yup.string().required("Este campo es requerido").nullable(),
  status: yup.string().required("Este campo es requerido").nullable(),
});

export const historicoLecturasValidation = yup.object({
  measurements_last_month: yup
    .string()
    .required("Este campo es requerido")
    .nullable(),
});

export const frontierControlReportValidation = yup.object({
  since: yup.string().required("Este campo es requerido").nullable(),
  until: yup.string().required("Este campo es requerido").nullable(),
});

export const materialReportsValidation = yup.object({
  since: yup.string().required("Este campo es requerido").nullable(),
  until: yup.string().required("Este campo es requerido").nullable(),
  state: yup.string().required("Este campo es requerido").nullable(),
  material_used: yup.string().required("Este campo es requerido").nullable(),
});

export const materialDiagnosticsValidation = yup.object({
  since: yup
    .string()
    .nullable()
    .default("")
    .required("Este campo es requerido"),
  until: yup
    .string()
    .nullable()
    .default("")
    .required("Este campo es requerido"),
  lead: yup.string().email("Ingresa un email válido"),
});
