import {
  Box,
  useFormik,
  LoadingButton,
  CustomAlert,
  InfoIcon,
  Typography,
  //@ts-ignore
} from "@enerbit/base";
import { FC, useEffect, useState } from "react";
import { historicoLecturas } from "../../../../const/formSchemas";
import { lastActiveEmergy } from "../../../../const/lastActive";
import { optionsReports } from "../../../../const/reportJson";
import { FieldLabelI, FormLabelI } from "../../../../models/Interfaces";
import { postReports } from "../../../../services/postOptions";
import FormItem from "../FormItem";

interface Props {
  option: string;
}

export const HistoryReadings: FC<Props> = (option) => {
  const [formData, setFormData] = useState<FormLabelI>(historicoLecturas);
  const [validationSchema, setValidationSchema] = useState<{}>();
  const [selectedOptionReport, setSelectedOptionReport] = useState(option["option"]);
  const [isLoadingSendForm, setIsLoadingSendForm] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [severityAlert, setSeverityAlert] = useState("");
  const [textAlert, setTexAlert] = useState("");

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: () => {
      formik.resetForm();
      handleSubmitForm();
    },
  });

  useEffect(() => {
    if (selectedOptionReport && optionsReports[selectedOptionReport].fields.length > 0) {
      setFormData(optionsReports[selectedOptionReport].schema);
      setValidationSchema(optionsReports[selectedOptionReport].validation);
    } else {
      setValidationSchema({});
    }
  }, [selectedOptionReport]);

  const closeAlert = () => {
    setTexAlert("");
    setSeverityAlert("");
  };

  const resetForm = (): void => {
    if (optionsReports[selectedOptionReport].fields.length > 0) {
      setFormData({
        measurements_last_month: "",
        email_recipient: "",
      });
      return;
    }
  };

  const handleSubmitForm = async () => {
    setIsLoadingSendForm(true);
    const body = formData;
    try {
      await postReports(optionsReports[selectedOptionReport].url, body);
      setSeverityAlert("success");
      setTexAlert("Reporte generado, por favor revisa el correo aproximadamente en 5 minutos");
      resetForm();
    } catch (err) {
      setSeverityAlert("error");
      setTexAlert("No se pudo generar y enviar el reporte");
      return err;
    } finally {
      setIsLoadingSendForm(false);
    }
  };

  return (
    <>
      <Box
        component='form'
        onSubmit={
          (validationSchema
            ? formik.handleSubmit
            : (e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                handleSubmitForm();
              }) as any
        }>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}>
          {optionsReports[selectedOptionReport].fields.map((field: FieldLabelI, index: number) => (
            <FormItem
              field={field}
              key={index}
              formData={formData}
              setFormData={setFormData}
              formik={formik}
            />
          ))}
        </Box>
        <Box sx={{ display: "flex", marginTop: "10px" }}>
          <InfoIcon sx={{ color: "#ED7004", marginTop: "-10px" }} />
          <Typography
            color='primary'
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              margin: "20px 0",
              marginTop: "-10px",
              marginLeft: "10px",
              width: "550px",
            }}>
            {optionsReports[selectedOptionReport].information}
          </Typography>
        </Box>
        <LoadingButton
          color='secondary'
          variant='contained'
          fullWidth
          type='submit'
          sx={{ my: 2 }}
          disabled={!isSuccess}
          loading={isLoadingSendForm}>
          Generar reporte y enviar al correo notificado
        </LoadingButton>
      </Box>
      {severityAlert && (
        <Box sx={{ my: 1 }}>
          <CustomAlert severity={severityAlert as any} text={textAlert} onClose={closeAlert} />
        </Box>
      )}
    </>
  );
};
