import React, { useState } from "react";
import { FieldLabelI, FormLabelI } from "../../../../models/Interfaces";
import { Box, InfoIcon, Typography, LoadingButton, CustomAlert, styled } from "@enerbit/base";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { frontierControlReports } from "../../../../const/formSchemas";
import { optionsReports } from "../../../../const/reportJson";
import { materialReportsValidation } from "../../../../const/validationSchema";
import { updateHasError, updateEmpty } from "../../../../store/slice/reports/reportsSlice";
import { AppDispatch, RootState } from "../../../../store/store";
import { getMaterialReports } from "../../../../store/thunks";
import FormItem from "../FormItem";
import LinearProgressWithLabel from "../LinearProgressWithLabel";

interface Props {
  option: string;
}

const MaterialReports = ({ option }: Props) => {
  const [formData, setFormData] = useState<FormLabelI>(frontierControlReports);

  const dispatch = useDispatch<AppDispatch>();
  const isLoadingReports = useSelector((state: RootState) => state.reports.isLoadingReports);
  const empty = useSelector((state: RootState) => state.reports.empty);
  const hasError = useSelector((state: RootState) => state.reports.hasError);
  const progress = useSelector((state: RootState) => state.reports.downloadProgress);

  const formik = useFormik({
    initialValues: formData,
    validationSchema: materialReportsValidation,
    onSubmit: () => {
      formik.resetForm();
      handleSubmitForm();
    },
  });

  const resetForm = (): void => {
    if (optionsReports[option].fields.length > 0) {
      setFormData({
        since: "",
        until: "",
        material_used: "",
        state: "",
      });
      return;
    }
  };

  const handleSubmitForm = async () => {
    const body = formData;
    await dispatch(getMaterialReports({ url: optionsReports[option].url, data: body }));
    resetForm();
  };
  
  return (
    <>
      <form
        onSubmit={
          (materialReportsValidation
            ? formik.handleSubmit
            : (e: React.FormEvent<HTMLInputElement>) => {
                e.preventDefault();
                handleSubmitForm();
              }) as any
        }>
        <FormContainer grid_columns={2}>
          {optionsReports[option].fields.map((field: FieldLabelI, index: number) => (
            <FormItem
              field={field}
              key={index}
              formData={formData}
              setFormData={setFormData}
              formik={formik}
            />
          ))}
        </FormContainer>
        <Box sx={{ display: "flex", marginTop: "24px" }}>
          <InfoIcon sx={{ color: "#ED7004", marginTop: "-10px" }} />
          <Typography
            color='primary'
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              margin: "20px 0",
              marginTop: "-10px",
              marginLeft: "10px",
            }}>
            {
              "Recuerda que los datos del archivo están separados por punto y coma (;). Para verlos mejor te recomendamos abrir el archivo en Excel."
            }
          </Typography>
        </Box>
        {!isLoadingReports && (
          <LoadingButton
            fullWidth
            type='submit'
            sx={{ my: 2 }}
            color='secondary'
            variant='contained'>
            Generar y descargar reporte
          </LoadingButton>
        )}
      </form>
      {hasError && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity='error'
            text={"Error generando el reporte. Intenta otra vez."}
            onClose={() => dispatch(updateHasError())}
          />
        </Box>
      )}
      {empty && (
        <Box sx={{ my: 1 }}>
          <CustomAlert
            severity='info'
            text={"No existen reportes para este rango de fechas. Intenta con otro."}
            onClose={() => dispatch(updateEmpty(false))}
          />
        </Box>
      )}
      {isLoadingReports && !empty && (
        <Box mt={2}>
          <LinearProgressWithLabel
            title='Generando reportes...'
            sx={{ height: "15px", borderRadius: "10px" }}
            value={progress}
          />
        </Box>
      )}
    </>
  );
};

export default MaterialReports;

type Grid = {
  grid_columns: number;
};

const FormContainer = styled("div")(({ grid_columns }: Grid) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
