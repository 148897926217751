import { getStates } from "../services/getStates";
import {
  frontierControlReports,
  historicoLecturas,
  materialDiagnostics,
  materialDiagnosticsSolar,
  materialReports,
  reporteInstalacion,
  scheduledVsExecuted,
} from "./formSchemas";
import {
  frontierControlReportValidation,
  historicoLecturasValidation,
  materialDiagnosticsValidation,
  materialReportsValidation,
  reporteInstalacionValidation,
  scheduledVsExecutedValidation,
} from "./validationSchema";
let stateList: String[] = ["Todos los departamentos"];

getStates().then((value) => value.map((x) => stateList.push(x)));

export const listReports = [
  // {
  //   text: "Reporte de instalación",
  //   value: "reporteInstalacion",
  // },
  {
    text: "Reporte de órdenes programadas vs ejecutadas",
    value: "reporteOrdenesProgramadasEjecutadas",
  },
  // {
  //   text: "Histórico de lecturas",
  //   value: "historicoLecturas",
  // },
  {
    text: "Reporte de control de fronteras",
    value: "frontierControlReports",
  },
  {
    text: "Reporte de materiales instalados",
    value: "materialReports",
  },
  {
    text: "Reporte de materiales proyectados en el diagnostico de viabilidad y cumplimiento",
    value: "materialDiagnostics",
  },
  {
    text: "Reporte de materiales diagnostico de viabilidad solar",
    value: "materialDiagnosticsSolar",
  },
];

export const optionsReports = {
  reporteInstalacion: {
    schema: reporteInstalacion,
    validation: reporteInstalacionValidation,
    url: "/field-service-certification-reports/installation-reports-per-day",
    information:
      "Recuerda que si selecciona mes actual no podrás ver las lecturas, debes ingresar desde un mes anterior",
    fields: [
      {
        name: "since",
        label: "Desde",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
      },
      {
        name: "until",
        label: "Hasta",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
      },
      {
        name: "measurements_last_month",
        label: "Fecha de lectura",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
      },
      {
        name: "email_recipient",
        label: "Email de la persona que lo recibe (opcional)",
        value: "",
        type: "email",
        isSelector: false,
        isDate: false,
      },
    ],
  },
  reporteOrdenesProgramadasEjecutadas: {
    schema: scheduledVsExecuted,
    validation: scheduledVsExecutedValidation,
    url: "/reports/scheduled-vs-executed",
    fields: [
      {
        name: "since",
        label: "Fecha minima desde que se ejecutó la orden",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: false,
      },
      {
        name: "until",
        label: "Fecha maxima desde que se ejecutó la orden",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: false,
      },
      {
        name: "state",
        label: "Departamento",
        value: "",
        type: "text",
        isSelector: true,
        items: stateList,
        isDate: false,
      },
      {
        name: "status",
        label: "Estado",
        value: "complete",
        type: "text",
        isSelector: true,
        items: [
          {
            label: "Cancelado",
            value: "cancel",
          },
          {
            label: "Pendiente",
            value: "pending",
          },
          {
            label: "Hecho",
            value: "done",
          },
          {
            label: "Todos",
            value: "complete",
          },
        ],
        isDate: false,
      },
    ],
  },
  historicoLecturas: {
    schema: historicoLecturas,
    validation: historicoLecturasValidation,
    url: "/field-service-certification-reports/installation-reports-send-email/",
    information:
      "Recuerda que si selecciona mes actual no podrás ver las lecturas, debes ingresar desde un mes anterior",
    fields: [
      {
        name: "measurements_last_month",
        label: "Fecha de lectura",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
      },
      {
        name: "email_recipient",
        label: "Email de la persona que lo recibe (opcional)",
        value: "",
        type: "email",
        isSelector: false,
        isDate: false,
      },
    ],
  },
  frontierControlReports: {
    schema: frontierControlReports,
    validation: frontierControlReportValidation,
    url: "/reports/cdm-frontiers",
    fields: [
      {
        name: "since",
        label: "Fecha de inicio",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        limitDateRange: true,
      },
      {
        name: "until",
        label: "Fecha de finalización",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        limitDateRange: true,
      },
    ],
  },
  materialReports: {
    schema: materialReports,
    validation: materialReportsValidation,
    url: "/reports/report-material/",
    fields: [
      {
        name: "since",
        label: "Fecha de inicio",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: true,
      },
      {
        name: "until",
        label: "Fecha de finalización",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: true,
      },
      {
        name: "state",
        label: "Departamento",
        value: "",
        type: "text",
        isSelector: true,
        isDate: false,
        items: stateList,
      },
      {
        name: "material_used",
        label: "Acta",
        value: "",
        type: "text",
        isSelector: true,
        isDate: false,
        items: [
          { name: "Todos", value: "complete" },
          { name: "Instalación", value: "installation" },
          { name: "Normalización", value: "normalization" },
          { name: "Instalación SmartBit", value: "smartbit" },
        ],
        isCustomSelect: true,
      },
    ],
  },
  materialDiagnostics: {
    schema: materialDiagnostics,
    validation: materialDiagnosticsValidation,
    url: "/reports/compliance-diagnostic",
    fields: [
      {
        name: "since",
        label: "Fecha de inicio",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: true,
      },
      {
        name: "until",
        label: "Fecha de finalización",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: true,
      },
      {
        name: "lead",
        label: "Lead (opcional)",
        value: "",
        type: "text",
      },
    ],
  },
  materialDiagnosticsSolar: {
    schema: materialDiagnosticsSolar,
    validation: materialDiagnosticsValidation,
    url: "/reports/solar-compliance-diagnostic/",
    fields: [
      {
        name: "since",
        label: "Fecha de inicio",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: true,
      },
      {
        name: "until",
        label: "Fecha de finalización",
        value: "",
        type: "text",
        isSelector: false,
        isDate: true,
        isFormat: true,
        limitDateRange: true,
      },
      {
        name: "lead",
        label: "Lead (opcional)",
        value: "",
        type: "text",
      },
    ],
  },
};
