import type { Geolocalization, ScheduledVsExecutedItem } from "../models/scheduledVsExecuted";
import { formatDate, formatOrderInconsistencies, getOrderStatus } from "./reports";

export const rowJson = (item: ScheduledVsExecutedItem, locationOrder: Geolocalization | string) => {
  return {
    // Detalles de la Orden
    "ID ORDEN": item.id,
    "ACTIVIDAD PRINCIPAL PROGRAMADA": item.order_type_name.split("\n").join("").replace(";", ","),
    "ESTADO DE LA ORDEN": getOrderStatus(item.status),
    "UBICACIÓN DE LA ORDEN": locationOrder,
    "FECHA Y HORA PROGRAMADA": formatDate(item.planned_date_begin),

    // Detalles de la Instalación
    "TIPO DE MEDIDA": item.installation ? item.installation?.measurement_type : "No aplica",
    "MEDIDOR INSTALADO": item.installation ? item.installation?.main_meter_installed : "No aplica",
    "LECTURA IMPORTACIÓN ACTIVA": item.installation
      ? item.installation?.active_energy_imported
      : "No aplica",
    "COMUNICACIONES MEDIDOR INSTALADO": item.installation
      ? item.installation?.main_meter_comunications_installed
      : "No aplica",
    "NOMBRE DEL OPERADOR DE RED":
      item.installation && item.installation?.network_operator
        ? item.installation?.network_operator.fullname
        : "No aplica",
    "CORREO DEL OPERADOR DE RED":
      item.installation && item.installation?.network_operator
        ? item.installation?.network_operator.email
        : "No aplica",
    "FECHA Y HORA DE INSTALACIÓN": item.installation
      ? formatDate(item.installation?.installed_at)
      : "No aplica",
    "MEDIDOR RETIRADO": item.installation
      ? item.installation.main_meter_retired
        ? item.installation.main_meter_retired
        : "Sin registro"
      : "No aplica",
    "LECTURA RETIRADO": item.installation
      ? item.installation.active_energy_imported_retired
        ? item.installation.active_energy_imported_retired
        : "Sin registro"
      : "No aplica",

    // Detalles de la Ejecución/Cancelación
    "FECHA Y HORA DE INICIO":
      item.status === "done"
        ? formatDate(item.order_done?.form_data.metric?.started_at!)
        : "No aplica",
    "FECHA Y HORA DE FIN":
      item.status === "done"
        ? formatDate(item.order_done?.form_data.metric?.ended_at!)
        : "No aplica",
    "MOTIVO DE CANCELACIÓN":
      item.status === "cancel"
        ? item.order_cancel?.cancellation_reason?.name.split("\n").join("").replace(";", ",")
        : "No aplica",
    "OBSERVACIÓN DE CANCELACIÓN":
      item.status === "cancel"
        ? item.order_cancel?.observations.split("\n").join("").replace(";", ",")
        : "No aplica",
    "FECHA Y HORA DE CANCELACIÓN":
      item.status === "cancel" ? formatDate(item.order_cancel?.created_at!) : "No aplica",

    "CÓDIGO DICEL":
      item.entity_pii && item.entity_pii?.dc_code ? item.entity_pii.dc_code : "No aplica",
    "CÓDIGO SIC":
      item.entity_pii && item.entity_pii?.sic_code ? item.entity_pii.sic_code : "No aplica",
    DEPARTAMENTO: item.state,
    CIUDAD: item.city,
    DIRECCIÓN:
      item.entity_pii && item.entity_pii.address
        ? item.entity_pii.address.split("\n").join("").replace(";", ",")
        : "Sin registro",
    PROPIETARIO:
      item.entity_pii && item.entity_pii.full_name ? item.entity_pii.full_name : "Sin registro",
    CORREO: item.entity_pii && item.entity_pii.email ? item.entity_pii.email : "Sin registro",
    "OBSERVACIÓN GENERAL":
      item.description.split("\n").join("").replace(";", ",") ?? "Sin registro",
    ...formatOrderInconsistencies(item),

    // Detalles del Operador
    "NOMBRE DEL OPERADOR EN CAMPO": item.operator_pii.full_name,
    "CORREO DEL OPERADOR EN CAMPO": item.operator_pii.email,

    "NÚMERO DE LA ORDEN DEL OR": item.installation?.or_order_number ?? "Sin registro",
  };
};
