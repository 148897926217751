import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Select,
  MenuItem,
  Autocomplete,
  LocalizationProvider,
  AdapterDayjs,
  DatePicker,
  FormHelperText,
  FormControlLabel,
  Switch,
  Typography,
  CancelIcon,
  CheckCircleIcon,
  ErrorIcon,
  Grid,
  dayjs,
  Dayjs,
} from "@enerbit/base";
import { FormikProps } from "formik";
import { useState, useMemo } from "react";
import { FieldLabelI, FormLabelI } from "../../../models/Interfaces";

interface FormItemI {
  field: FieldLabelI;
  formData: FormLabelI;
  setFormData: React.Dispatch<React.SetStateAction<FormLabelI>>;
  formik: FormikProps<any>;
}

const FormItem = ({ field, formData, setFormData, formik }: FormItemI) => {
  const [inputValue, setInputValue] = useState("");

  const maxDate = useMemo(() => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${year}/0${month}/${day}`;
  }, []);

  return (
    <Box
      sx={
        !field.isSelector &&
        !field.isDate &&
        !field.isTextarea &&
        !field.isArray &&
        !field.isBoolean &&
        !field.isNoRender
          ? { mt: 1, marginTop: "0px" }
          : { mt: 1 }
      }
    >
      {!field.isSelector &&
        !field.isDate &&
        !field.isTextarea &&
        !field.isArray &&
        !field.isBoolean &&
        !field.isNoRender && (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              id="outlined-basic"
              sx={{ "& fieldset": { borderRadius: "12px" } }}
              label={field.label}
              variant="outlined"
              error={
                formik.touched[field.name] && Boolean(formik.errors[field.name])
              }
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
              value={
                (formik.values[field.name] =
                  formData[field.name as keyof FormLabelI])
              }
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({
                  ...formData,
                  [field.name]: target.value,
                })
              }
            />
          </FormControl>
        )}
      {field.isCustomSelect && field.isSelector && (
        <FormControl fullWidth sx={{ my: 1, marginTop: "0px" }}>
          <InputLabel>{field.label}</InputLabel>
          <Select
            defaultValue={null}
            error={
              formik.touched[field.name] && Boolean(formik.errors[field.name])
            }
            input={<OutlinedInput label={field.label} />}
            value={
              (formik.values[field.name] =
                formData[field.name as keyof FormLabelI] || null) as any
            }
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{ borderRadius: "12px" }}
            onChange={
              (({ target }: React.ChangeEvent<HTMLInputElement>) =>
                setFormData({
                  ...formData,
                  [field.name]: target.value,
                })) as any
            }
          >
            {field.items.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error>
            {formik.touched[field.name] && formik.errors[field.name]}
          </FormHelperText>
        </FormControl>
      )}
      {field.isSelector &&
        field.items &&
        field.items.length <= 6 &&
        !field.isCustomSelect && (
          <FormControl fullWidth sx={{ my: 1, marginTop: "0px" }}>
            <InputLabel>{field.label}</InputLabel>
            <Select
              defaultValue={null}
              error={
                formik.touched[field.name] && Boolean(formik.errors[field.name])
              }
              input={<OutlinedInput label={field.label} />}
              value={
                (formik.values[field.name] =
                  formData[field.name as keyof FormLabelI] || null) as any
              }
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{ borderRadius: "12px" }}
              onChange={
                (({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    [field.name]: target.value,
                  })) as any
              }
            >
              {field.items.map((fieldOption: FieldLabelI, index: number) =>
                fieldOption.label == "Cancelado" ? (
                  <MenuItem key={index} value={fieldOption.value}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CancelIcon
                        sx={{ color: "#f04438", marginRight: "10px" }}
                      />
                      {fieldOption.label}
                    </Box>
                  </MenuItem>
                ) : fieldOption.label == "Pendiente" ? (
                  <MenuItem key={index} value={fieldOption.value}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <ErrorIcon
                        sx={{ color: "#F79009", marginRight: "10px" }}
                      />
                      {fieldOption.label}
                    </Box>
                  </MenuItem>
                ) : fieldOption.label == "Hecho" ? (
                  <MenuItem key={index} value={fieldOption.value}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CheckCircleIcon
                        sx={{ color: "#13b76a", marginRight: "10px" }}
                      />
                      {fieldOption.label}
                    </Box>
                  </MenuItem>
                ) : (
                  <MenuItem key={index} value={fieldOption.value}>
                    {fieldOption.label}
                  </MenuItem>
                )
              )}
            </Select>
            <FormHelperText error>
              {formik.touched[field.name] && formik.errors[field.name]}
            </FormHelperText>
          </FormControl>
        )}
      {field.items && field.items.length > 6 && (
        <Autocomplete
          id="disabled-options-demo"
          defaultValue=""
          options={field?.items}
          getOptionLabel={(option: string) => option || inputValue}
          isOptionEqualToValue={(option: string, value: string) =>
            option === value
          }
          value={
            ((formik.values[field?.name] =
              formData[field?.name as keyof FormLabelI]) as any) || null
          }
          fullWidth
          inputValue={inputValue}
          onInputChange={
            ((_: string, value: string) => setInputValue(value)) as any
          }
          onChange={
            ((_: string, newValue: string) => {
              setFormData({
                ...formData,
                [field.name]: newValue,
              });
            }) as any
          }
          renderInput={(params: {}) => (
            <TextField
              sx={{ "& fieldset": { borderRadius: "12px" } }}
              {...params}
              label={field.label}
              error={
                formik.touched[field.name] && Boolean(formik.errors[field.name])
              }
              helperText={
                formik.touched[field.name] && formik.errors[field.name]
              }
            />
          )}
        />
      )}
      {field.isDate && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <FormControl sx={{ my: 1 }} fullWidth>
            <DatePicker
              label={field.label}
              value={
                (formik.values[field?.name] =
                  formData[field?.name as keyof FormLabelI] || null)
              }
              format="YYYY/MM/DD"
              maxDate={field.limitDateRange ? (dayjs() as any) : undefined}
              onChange={(newValue: Date | any) => {
                setFormData({
                  ...formData,
                  [field.name]: field.isFormat
                    ? newValue.format("YYYY-MM-DD")
                    : newValue,
                });
              }}
              slotProps={{
                textField: {
                  helperText:
                    formik.touched[field.name] && formik.errors[field.name],
                  error:
                    formik.touched[field.name] &&
                    Boolean(formik.errors[field.name]),
                  sx: { "& fieldset": { borderRadius: "12px" } },
                },
              }}
            />
          </FormControl>
        </LocalizationProvider>
      )}
      {field.isBoolean && (
        <>
          <Typography color="primary" sx={{ mt: 1 }}>
            {field.label}
          </Typography>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={
                  formData[field.name as keyof FormLabelI] ? true : false
                }
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
                  setFormData({
                    ...formData,
                    [field.name]: target.checked,
                  });
                }}
              />
            }
            label={formData[field.name as keyof FormLabelI] ? "Si" : "No"}
          />
        </>
      )}
      {field.isTextarea && (
        <TextField
          placeholder={field.label}
          multiline
          value={
            (formik.values[field.name] =
              formData[field.name as keyof FormLabelI])
          }
          fullWidth
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
            setFormData({
              ...formData,
              [field.name]: target.value,
            })
          }
          error={
            formik.touched[field.name] && Boolean(formik.errors[field.name])
          }
          helperText={formik.touched[field.name] && formik.errors[field.name]}
        />
      )}
      {field.isArray && (
        <>
          <Typography color="primary">{field.label}</Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          ></Box>
        </>
      )}
    </Box>
  );
};

export default FormItem;
