export const scheduledVsExecutedHeaders = [
  "ESTADO DE LAS ORDENES",
  "FECHA PROGRAMADA",
  "ACTIVIDAD PRINCIPAL PROGRAMADA",
  "OTRAS ACTIVIDADES PROGRAMADAS",
  "LEAD ID ODDO",
  "NIU (LEAD)",
  "FRT (CLIENTE)",
  "DEPARTAMENTO",
  "DIRECCION",
  "PROPIETARIO",
  "NUMERO DE DOCUMENTO",
  "EMAIL PROPIETARIO",
  "CORREO OPERADOR EN CAMPO 1",
  "CORREO OPERADOR EN CAMPO 2",
];

export interface ScheduledVsExecutedModel {
  items: ScheduledVsExecutedItem[];
  page: number;
  size: number;
  total: number;
  pages: number;
  next_page: null;
  previous_page: null;
}

export interface ScheduledVsExecutedItem {
  id: string;
  status: Status;
  state: string;
  city: string;
  description: string;
  order_type_name: string;
  operator_pii: OperatorPii;
  entity_pii: EntityPii;
  order_done: OrderDone | null;
  order_cancel: OrderCancel | null;
  installation: Installation | null;
  planned_date_begin: Date;
  planned_date_end: Date;
}

export interface EntityPii {
  full_name: string;
  address: string;
  estate: string;
  city: string;
  entity_type: string;
  email: string;
  dc_code: string;
  sic_code: string | null;
}

export interface Installation {
  main_meter_installed: string;
  main_meter_retired: string;
  active_energy_imported: number;
  active_energy_imported_retired: number;
  measurement_type: string;
  main_meter_comunications_installed: string;
  installed_at: Date;
  network_operator: NetworkOperator | null;
  or_order_number: string;
}

export interface NetworkOperator {
  fullname: string;
  legal_id_type: string;
  legal_id_code: string;
  email: string;
  signature: string;
}

export interface OperatorPii {
  full_name: string;
  rol: string;
  email: string;
  company: string;
  company_address: string;
}

export interface OrderCancel {
  id: string;
  cancel_by: string;
  form_data: OrderCancelFormData;
  cancellation_reason: CancellationReason;
  observations: string;
  picture_url: string;
  created_at: Date;
  updated_at: Date | null;
}
export interface CancellationReason {
  id: string;
  name: string;
  code: string;
  created_at: Date;
  updated_at: Date | null;
}

export interface OrderCancelFormData {
  geolocalization: Geolocalization;
}

export interface Geolocalization {
  altitude: number;
  latitude: number;
  longitude: number;
}

export interface OrderDone {
  id: string;
  done_by: string;
  form_data: OrderDoneFormData;
  created_at: Date;
  updated_at: Date | null;
}

export interface OrderDoneFormData {
  geolocalization: Geolocalization;
  metric: Metric | null;
  inconsistencies?: Inconsistencies[];
}

export interface Metric {
  ended_at: Date;
  started_at: Date;
}

export enum InconsistencyTypes {
  LATE_START = "late_start",
  EXCEEDED_TIME = "exceeded_time",
  DIFFERENT_LOCATION = "different_location",
}

export interface Inconsistencies {
  inconsistency_type: InconsistencyTypes;
  reason: string;
  different_location: {
    initial: Geolocalization;
    final: Geolocalization;
  } | null;
  scheduled_time_difference: {
    scheduled_time: string;
    actual_time: string;
  } | null;
  time_exceeded_average: { average: number; actual: number } | null;
}

export enum Status {
  Done = "done",
  Pending = "pending",
  Cancel = "cancel",
  Pending_Assigment = "pending_assignment",
  Partial_Done = "partial_done",
}

export interface RowJsonOutput {
  "ID ORDEN": string;
  "ACTIVIDAD PRINCIPAL PROGRAMADA": string;
  "ESTADO DE LA ORDEN": string;
  "UBICACIÓN DE LA ORDEN": Geolocalization | string;
  "FECHA Y HORA PROGRAMADA": string;
  "TIPO DE MEDIDA": string;
  "MEDIDOR INSTALADO": string;
  "LECTURA IMPORTACIÓN ACTIVA": string;
  "COMUNICACIONES MEDIDOR INSTALADO": string;
  "NOMBRE DEL OPERADOR DE RED": string;
  "CORREO DEL OPERADOR DE RED": string;
  "FECHA Y HORA DE INSTALACIÓN": string;
  "MEDIDOR RETIRADO": string;
  "LECTURA RETIRADO": string;
  "FECHA Y HORA DE INICIO": string;
  "FECHA Y HORA DE FIN": string;
  "MOTIVO DE CANCELACIÓN": string;
  "OBSERVACIÓN DE CANCELACIÓN": string;
  "FECHA Y HORA DE CANCELACIÓN": string;
  "CÓDIGO DICEL": string;
  "CÓDIGO SIC": string;
  DEPARTAMENTO: string;
  CIUDAD: string;
  DIRECCIÓN: string;
  PROPIETARIO: string;
  CORREO: string;
  "OBSERVACIÓN GENERAL": string;
}
