import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatDate } from "../../helpers/reports";
import { GetReportsModel } from "../../models/Interfaces";
import { DiagnosticMaterialSolarItem } from "../../models/materialSolarDiagnostics";
import { updateDownloadProgress, updateEmpty, updateIsLoading } from "../slice/reports/reportsSlice";
import { RootState } from "../store";

export const getDiagnosticMaterialSolarReport = createAsyncThunk(
  "reports/getDiagnosticMaterialSolarReport",
  async ({ url, data }: GetReportsModel, thunkApi) => {
    if (!data.lead && !data.since && !data.until) return;

    const since = `${data.since}T00:00:00`;
    const until = `${data.until}T23:59:59`;

    let currentPage = 0;
    let totalPages = 0;

    const totalItems: Record<string, DiagnosticMaterialSolarItem> = {};

    do {
      const params = { since, until, page: currentPage, size: 10 };

      const response = await api.get(url, {
        params,
      });

      totalPages = response.data.pages;
      currentPage = response.data.page + 1;

      if (totalPages === 0 || response.data.items.length == 0) {
        thunkApi.dispatch(updateIsLoading(false));
        thunkApi.dispatch(updateEmpty(true));
        break;
      }
      const state = thunkApi.getState() as RootState;
      const downloadProgress = state.reports?.downloadProgress;

      const progressStep = 100 / totalPages;

      const bodyProgress = {
        downloadProgress: downloadProgress + progressStep,
      };
      thunkApi.dispatch(updateDownloadProgress(bodyProgress));

      response.data.items.forEach((item: DiagnosticMaterialSolarItem) => {
        totalItems[item.solar_inspection.created_at] = item;
      });
    } while (currentPage < totalPages);

    if (Object.keys(totalItems).length === 0) {
      return;
    }

    const mappedItems = Object.values(totalItems)
      .map((item) => {
        const client = item.lead.display_name ?? "Sin registro";
        const city = item.lead.city ?? "Sin registro";
        const date = item.solar_inspection.created_at ? formatDate(item.solar_inspection.created_at) : "Sin registro";
        const responsible = item.operator ?? "Sin registro";

        return item.materials.map((material) => {
          if (material.quantity) {
            return {
              Cliente: client,
              Ciudad: city,
              "Fecha de inspección previa": date,
              Materiales: material.name ?? "Sin registro",
              Cantidad: material.quantity ?? "Sin registro",
              "Persona que aprobó el informe": responsible,
              "Herramientas especiales": item.special_tool,
            };
          } else {
            return {};
          }
        });
      })
      .flat()
      .filter((item) => Object.keys(item).length > 0);

    return mappedItems;
  }
);
