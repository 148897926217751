//@ts-ignore
import { api } from "@enerbit/base";
import { FormLabelI } from "../models/Interfaces";

export const postReports = async (
  url: string,
  body: FormLabelI
): Promise<String[]> => {
  const data: String[] = await api.post(url, body);
  return data;
};
