import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetReportsModel } from "../../models/Interfaces";
import { api } from "@enerbit/base";
import {
  updateDownloadProgress,
  updateEmpty,
  updateIsLoading,
} from "../slice/reports/reportsSlice";
import { RootState } from "../store";
import { DiagnosticMaterialItem } from "../../models/materialDiagnostics";
import { formatDate } from "../../helpers/reports";

export const getDiagnosticMaterialReport = createAsyncThunk(
  "reports/getDiagnosticMaterialReport",
  async ({ url, data }: GetReportsModel, thunkApi) => {
    if (!data.lead && !data.since && !data.until) return;

    const since = `${data.since}T00:00:00`;
    const until = `${data.until}T23:59:59`;

    let currentPage = 0;
    let totalPages = 0;

    const totalItems: Record<string, DiagnosticMaterialItem> = {};

    do {
      const params = { since, until, page: currentPage, size: 10 };

      const response = await api.get(url, {
        params,
      });

      totalPages = response.data.pages;
      currentPage = response.data.page + 1;

      if (totalPages === 0) {
        thunkApi.dispatch(updateIsLoading(false));
        thunkApi.dispatch(updateEmpty(true));
        break;
      }
      const state = thunkApi.getState() as RootState;
      const downloadProgress = state.reports?.downloadProgress;

      const progressStep = 100 / totalPages;

      const bodyProgress = {
        downloadProgress: downloadProgress + progressStep,
      };

      thunkApi.dispatch(updateDownloadProgress(bodyProgress));

      const keys = Object.keys(totalItems);

      response.data.items.forEach((item: DiagnosticMaterialItem) => {
        if (!keys.includes(item.frontier) && item.frontier !== "") {
          totalItems[item.frontier] = item;
        } else if (keys.includes(item.frontier) && item.frontier !== "") {
          const date = new Date(totalItems[item.frontier].date_created);
          const itemDate = new Date(item.date_created);

          if (itemDate > date) {
            totalItems[item.frontier] = item;
          }
        }
      });
    } while (currentPage < totalPages);

    if (Object.keys(totalItems).length === 0) {
      return;
    }

    const mappedItems = Object.values(totalItems)
      .map((item) => {
        const client = item.customer ?? "Sin registro";
        const city = item.city ?? "Sin registro";
        const date = item.date_created
          ? formatDate(item.date_created)
          : "Sin registro";
        const responsible = item.emited_by.name ?? "Sin registro";

        return item.materials_and_labor.materials.map((material) => {
          if (material.quantity) {
            return {
              Cliente: client,
              Ciudad: city,
              "Fecha de inspección previa": date,
              Materiales: material.name ?? "Sin registro",
              Cantidad: material.quantity ?? "Sin registro",
              "Persona que aprobó el informe": responsible,
            };
          } else {
            return {};
          }
        });
      })
      .flat()
      .filter((item) => Object.keys(item).length > 0);

    return mappedItems;
  }
);
