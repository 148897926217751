import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetReportsModel } from "../../models/Interfaces";

import { initializeEventSource } from "../../helpers/eventSource";
import { formatMoney } from "../../helpers/reports";
import { getAcessToken } from "../../helpers/token";
import { MaterialItems, MaterialsData } from "../../models/materialReports";
import { RootState } from "../store";

export const getMaterialReports = createAsyncThunk(
	"reports/getMaterialReports",
	async ({ url, data }: GetReportsModel, { dispatch }) => {
		if (!data.since || !data.until || !data.material_used || !data.state)
			return;
		const since = `${data.since}T00:00:00`;
		const until = `${data.until}T23:59:59`;
		const report_material_used = data.material_used;
		const department =
			data.state !== "Todos los departamentos" ? data.state : "";
		console.log(data);
		const params = {
			since,
			until,
			report_material_used,
			department,
		};

		const token = getAcessToken();
		const itemsArray = (await initializeEventSource(
			url,
			"report-materials-used",
			token,
			dispatch,
			params,
		)) as MaterialItems[];

		const combinedArray: MaterialsData[] = itemsArray.reduce((acc, item) => {
			if (item.initial_installation && item.initial_installation.length > 0) {
				acc = acc.concat(item.initial_installation);
			}
			if (item.normalization && item.normalization.length > 0) {
				acc = acc.concat(item.normalization);
			}
			if (item.smarbit && item.smarbit.length > 0) {
				acc = acc.concat(item.smarbit);
			}
			return acc;
		}, [] as MaterialsData[]);

		if (combinedArray.length === 0) {
			return;
		}

		const mappedItems = combinedArray
			.map((item: MaterialsData) => {
				const createAt = item.created_at;
				const clientName = item.lead.name;
				const measurementType = item.measurement_type;
				const operator = item.operator.email;
				const orderType = item.type_order;

				return item.materials.map((mat) => {
					return {
						"FECHA DE EJECUCIÓN": createAt,
						"NOMBRE CLIENTE": clientName.split("\n").join("").replace(";", ","),
						"TIPO DE ORDEN": orderType,
						TÉCNICO: operator,
						"TIPO DE MEDIDA": measurementType,
						CATEGORÍA: mat.category || "Sin registro",
						MATERIAL: mat.name.split("\n").join("").replace(";", ","),
						"CÓDIGO SAP": mat.material_code_sap || "Sin registro",
						CANTIDAD: mat.quantity,
						VALOR: formatMoney(mat.charge),
						"NÚMERO DE SERIAL": mat.serial || "Sin registro",
					};
				});
			})
			.flat();

		return mappedItems;
	},
);
