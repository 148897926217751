import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GetReportsModel } from "../../models/Interfaces";
import { FrontierControlItem } from "../../models/frontierReports";
import {
  updateDownloadProgress,
  updateEmpty,
  updateIsLoading,
} from "../slice/reports/reportsSlice";
import { RootState } from "../store";
import moment from "moment";
import { authFile } from "../../helpers/reports";

export const getFrontierReports = createAsyncThunk(
  "reports/getReports",
  async ({ url, data }: GetReportsModel, thunkApi) => {
    if (!data.since || !data.until) return;

    let since = moment
      .utc(data.since.toString())
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    let until = moment
      .utc(data.until.toString())
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    let currentPage = 0;
    let totalPages = 0;

    const totalItems: FrontierControlItem[] = [];

    do {
      const response = await api.get(
        `${url}?since=${since}&until=${until}&size=15&page=${currentPage}`
      );
      totalPages = response.data.pages;
      currentPage = response.data.page + 1;

      if (totalPages === 0) {
        thunkApi.dispatch(updateIsLoading(false));
        thunkApi.dispatch(updateEmpty(true));
        break;
      }

      const state = thunkApi.getState() as RootState;
      const downloadProgress = state.reports.downloadProgress;

      const progressStep = 100 / totalPages;

      const bodyProgress = {
        downloadProgress: downloadProgress + progressStep,
      };

      thunkApi.dispatch(updateDownloadProgress(bodyProgress));

      response.data.items.forEach((item: FrontierControlItem) =>
        totalItems.push(item)
      );
    } while (currentPage < totalPages);

    if (totalItems.length === 0) {
      return;
    }

    const mappedItems = totalItems.map(async (item: FrontierControlItem) => {
      return {
        "CÓDIGO SIC": item?.frontier?.frontier_xm_code ?? "Sin registro",
        "NOMBRE FRONTERA": item?.lead?.display_name ?? "Sin registro",
        CIUDAD: item?.lead?.city ?? "Sin registro",
        "TIPO DE MEDIDA":
          item?.installation?.form_data?.main_meter_installed
            ?.measurement_type ?? "Sin registro",
        DEPARTAMENTO: item?.lead?.department ?? "Sin registro",
        OR: item?.lead?.current_network_operator ?? "Sin registro",
        "TENSION (kV)":
          item?.installation?.form_data?.general_data?.voltage_level ??
          "Sin registro",
        "NIVEL DE TENSIÓN":
          item?.installation?.form_data?.general_data?.voltage_level ??
          "Sin registro",
        "SERIE TRANFORMADOR":
          item?.installation?.form_data?.general_data
            ?.power_transformer_serial_number ?? "Sin registro",
        "CAPACIDAD (kVA)":
          item?.installation?.form_data?.general_data?.power_capacity_contracted
            ?.value ?? "Sin registro",
        MÚLTIPLO:
          item?.installation?.form_data?.general_data?.multiplier_factor ??
          "Sin registro",
        CONEXIÓN:
          item?.installation?.form_data?.general_data?.connection ??
          "Sin registro",
        "MARCA Y MODELO PRINCIPAL": `${
          item?.installation?.form_data?.main_meter_installed?.meter_brand ??
          "Sin registro"
        } - ${
          item?.installation?.form_data?.main_meter_installed?.meter_model ??
          "Sin registro"
        }`,
        "# SERIE MEDIDOR PRINCIPAL":
          item?.installation?.form_data?.main_meter_installed?.meter_serial
            ?.value ?? "Sin registro",
        "BLOQUE DE PRUEBAS PRINCIPAL": "PENDIENTE",
        "MARCA MODELO MODEM PRINCIPAL":
          item?.installation?.form_data?.main_meter_comunications_installed
            ?.brand ?? "Sin registro",
        "SERIE MODEM PRINCIPAL":
          item?.installation?.form_data?.main_meter_comunications_installed
            ?.serial_number?.value,
        "MARCA Y MODELO RESPALDO":
          item?.installation?.form_data?.optional_meter_comunications_installed
            ?.brand ?? "Sin registro",
        "# SERIE MEDIDOR RESPALDO":
          item?.installation?.form_data?.optional_meter_comunications_installed
            ?.serial_number?.value ?? "Sin registro",
        "BLOQUE DE PRUEBAS RESPALDO": "PENDIENTE",
        "MARCA MODELO MODEM RESPALDO":
          item?.installation?.form_data?.optional_meter_comunications_installed
            ?.brand ?? "Sin registro",
        "SERIE MODEM RESPALDO":
          item?.installation?.form_data?.optional_meter_comunications_installed
            ?.serial_number?.value ?? "Sin registro",
        "CALIBRE SECCIÓN 1 (AWG) Corriente":
          item?.installation?.form_data?.electrical_conductors_installed
            ?.current_signal?.gauge?.value ?? "Sin registro",
        "LONGITUD SECCIÓN 1 (m) Corriente":
          item?.installation?.form_data?.electrical_conductors_installed
            ?.current_signal?.length ?? "Sin registro",
        "MARCA CONDUCTOR Corriente":
          item?.installation?.form_data?.electrical_conductors_installed
            ?.current_signal?.manufacturer ?? "Sin registro",
        "CALIBRE SECCIÓN 1 (AWG) Tensión":
          item?.installation?.form_data?.electrical_conductors_installed
            ?.voltage_signal?.gauge?.value ?? "Sin registro",
        "LONGITUD SECCIÓN 1 (m) Tensión":
          item?.installation?.form_data?.electrical_conductors_installed
            ?.voltage_signal?.length ?? "Sin registro",
        "MARCA CONDUCTOR Tensión":
          item?.installation?.form_data?.electrical_conductors_installed
            ?.voltage_signal?.manufacturer ?? "Sin registro",
        "MARCA GABINETE": item?.installation?.form_data
          ?.electrical_boxes_installed
          ? item?.installation?.form_data?.electrical_boxes_installed[0]
              ?.manufacturer?.value
          : "Sin registro",
        "FECHA DE FABRICACION GABINETE": "qw",
        "MARCA TC1":
          item?.installation?.form_data?.current_transformer_installed?.r_phase
            ?.manufacturer ?? "Sin registro",
        "SERIES TC1":
          item?.installation?.form_data?.current_transformer_installed?.r_phase
            ?.serial_number?.value ?? "Sin registro",
        "CLASE DE PRECISIÓN TC1":
          item?.installation?.form_data?.current_transformer_installed?.r_phase
            ?.percentage_exactitude_class ?? "Sin registro",
        "RELACIÓN TC1":
          item?.installation?.form_data?.current_transformer_installed?.r_phase
            ?.transformation_relationship ?? "Sin registro",
        "VA PLACA TC1":
          item?.installation?.form_data?.current_transformer_installed?.r_phase
            ?.burden ?? "Sin registro",
        "VA CARGAS TC1":
          item?.installation?.form_data?.current_transformer_installed?.r_phase
            ?.charges_for_burden_compensation ?? "Sin registro",
        "MARCA TC2":
          item?.installation?.form_data?.current_transformer_installed?.s_phase
            ?.manufacturer ?? "Sin registro",
        "SERIES TC2":
          item?.installation?.form_data?.current_transformer_installed?.s_phase
            ?.serial_number?.value ?? "Sin registro",
        "CLASE DE PRECISIÓN TC2":
          item?.installation?.form_data?.current_transformer_installed?.s_phase
            ?.percentage_exactitude_class ?? "Sin registro",
        "RELACIÓN TC2":
          item?.installation?.form_data?.current_transformer_installed?.s_phase
            ?.transformation_relationship ?? "Sin registro",
        "VA PLACA TC2":
          item?.installation?.form_data?.current_transformer_installed?.s_phase
            ?.burden ?? "Sin registro",
        "VA CARGAS TC2":
          item?.installation?.form_data?.current_transformer_installed?.s_phase
            ?.charges_for_burden_compensation ?? "Sin registro",
        "MARCA TC3":
          item?.installation?.form_data?.current_transformer_installed?.t_phase
            ?.manufacturer ?? "Sin registro",
        "SERIES TC3":
          item?.installation?.form_data?.current_transformer_installed?.t_phase
            ?.serial_number?.value ?? "Sin registro",
        "CLASE DE PRECISIÓN TC3":
          item?.installation?.form_data?.current_transformer_installed?.t_phase
            ?.percentage_exactitude_class ?? "Sin registro",
        "RELACIÓN TC3":
          item?.installation?.form_data?.current_transformer_installed?.t_phase
            ?.transformation_relationship ?? "Sin registro",
        "VA PLACA TC3":
          item?.installation?.form_data?.current_transformer_installed?.t_phase
            ?.burden ?? "Sin registro",
        "VA CARGAS TC3":
          item?.installation?.form_data?.current_transformer_installed?.t_phase
            ?.charges_for_burden_compensation ?? "Sin registro",
        "MARCA TP":
          item?.installation?.form_data?.voltage_transformer_installed?.r_phase
            ?.manufacturer ?? "Sin registro",
        "SERIES TP":
          item?.installation?.form_data?.voltage_transformer_installed?.r_phase
            ?.serial_number?.value ?? "Sin registro",
        "CLASE DE PRECISIÓN TP1":
          item?.installation?.form_data?.voltage_transformer_installed?.r_phase
            ?.percentage_exactitude_class ?? "Sin registro",
        "RELACIÓN TP1":
          item?.installation?.form_data?.voltage_transformer_installed?.r_phase
            ?.transformation_relationship ?? "Sin registro",
        "VA PLACA TP1":
          item?.installation?.form_data?.voltage_transformer_installed?.r_phase
            ?.burden ?? "Sin registro",
        "VA CARGAS TP1":
          item?.installation?.form_data?.voltage_transformer_installed?.r_phase
            ?.charges_for_burden_compensation ?? "Sin registro",
        "MARCA TP2":
          item?.installation?.form_data?.voltage_transformer_installed?.s_phase
            ?.manufacturer ?? "Sin registro",
        "SERIES TP2":
          item?.installation?.form_data?.voltage_transformer_installed?.s_phase
            ?.serial_number?.value ?? "Sin registro",
        "CLASE DE PRECISIÓN TP2":
          item?.installation?.form_data?.voltage_transformer_installed?.s_phase
            ?.percentage_exactitude_class ?? "Sin registro",
        "RELACIÓN TP2":
          item?.installation?.form_data?.voltage_transformer_installed?.s_phase
            ?.transformation_relationship ?? "Sin registro",
        "VA PLACA TP2":
          item?.installation?.form_data?.voltage_transformer_installed?.s_phase
            ?.burden ?? "Sin registro",
        "VA CARGAS TP2":
          item?.installation?.form_data?.voltage_transformer_installed?.s_phase
            ?.charges_for_burden_compensation ?? "Sin registro",
        "MARCA TP3":
          item?.installation?.form_data?.voltage_transformer_installed?.t_phase
            ?.manufacturer ?? "Sin registro",
        "SERIES TP3":
          item?.installation?.form_data?.voltage_transformer_installed?.t_phase
            ?.serial_number?.value ?? "Sin registro",
        "CLASE DE PRECISIÓN TP3":
          item?.installation?.form_data?.voltage_transformer_installed?.t_phase
            ?.percentage_exactitude_class ?? "Sin registro",
        "RELACIÓN TP3":
          item?.installation?.form_data?.voltage_transformer_installed?.t_phase
            ?.transformation_relationship ?? "Sin registro",
        "VA PLACA TP3":
          item?.installation?.form_data?.voltage_transformer_installed?.t_phase
            ?.burden ?? "Sin registro",
        "VA CARGAS TP3":
          item?.installation?.form_data?.voltage_transformer_installed?.t_phase
            ?.charges_for_burden_compensation ?? "Sin registro",
        "FECHA DE DESCARGA": moment().format("DD/MM/YYYY"),
        "HORA DE DESCARGA": moment().format("hh:mm a"),
        "URL ACTA DE INSTALACIÓN": item.installation?.document_url
          ? await authFile(item.installation?.document_url)
          : "Sin registro",
      };
    });

    const reports = await Promise.all(mappedItems);

    return reports;
  }
);
