import { createAsyncThunk } from "@reduxjs/toolkit";
import type { FormLabelI } from "../../models/Interfaces";

import moment from "moment";

import { rowJson } from "../../helpers/scheduledVsExecuteJson";

import PQueue from "p-queue";
import { initializeEventSource } from "../../helpers/eventSource";
import { getAcessToken } from "../../helpers/token";
import type { ScheduledVsExecutedItem } from "../../models/scheduledVsExecuted";
import { getAddressSMB } from "../../services/assignee";

const queue = new PQueue({ concurrency: 1 });

export const getScheduledVsExecuted = createAsyncThunk(
	"reports/getScheduledVsExecuted",
	async (
		{ data, isClose = false }: { data: FormLabelI; isClose?: boolean },
		{ dispatch },
	) => {
		try {
			if (!data.since || !data.until || !data.status || !data.state) return;

			const status = data.status;
			const since = moment
				.utc(data.since)
				.startOf("day")
				.add(5, "hours")
				.format("YYYY-MM-DDTHH:mm:ss");
			const until = moment
				.utc(data.until)
				.endOf("day")
				.add(5, "hours")
				.format("YYYY-MM-DDTHH:mm:ss");
			const state = data.state;

			const params = {
				status,
				since,
				until,
				state,
			};

			const token = getAcessToken();
			const itemsArray = (await initializeEventSource(
				"/reports/sse/scheduled-vs-executed",
				"scheduled-vs-executed",
				token,
				dispatch,
				params,
				isClose,
			)) as ScheduledVsExecutedItem[];

			const mappedItems = await Promise.all(
				itemsArray.map(async (item) => {
					const location =
						item.order_done?.form_data.geolocalization ||
						item.order_cancel?.form_data.geolocalization;

					const locationOrder = location
						? `Altitud: ${location.altitude}, longitud: ${location.longitude} y latitud: ${location.latitude}`
						: "No aplica";

					let newItem = item;
					if (item?.entity_pii && item.entity_pii?.entity_type === "SMB") {
						try {
							const address = await queue.add(() => getAddressSMB(item.id));
							newItem = {
								...item,
								entity_pii: {
									...item.entity_pii,
									address,
								},
							};
						} catch (error) {
							newItem = {
								...item,
								entity_pii: {
									...item.entity_pii,
									address: "No registra",
								},
							};
						}
					}

					const row = rowJson(newItem, locationOrder);

					return row;
				}),
			);

			return mappedItems;
		} catch (error) {
			console.log(error);
		}
	},
);
