//@ts-ignore
import axios from "axios";
import type { dataStatesI } from "../models/Interfaces";

export const geoPoliticsApi = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_GEOPOLITICS_URL}/geopolitics`,
});

export const getStates = async (): Promise<string[]> => {
  const { data } = await geoPoliticsApi.get(
    "/states/?country_code=co&page=1&size=50"
  );
  const stateList = data?.items.map((state: dataStatesI) => state.name);
  return stateList;
};
