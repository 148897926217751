import moment from "moment";

export const getMaxDaysOfMonth = (date: Date | string): number => {
  const momentDate = moment(date);

  const dateTwoMonthsAfter = momentDate.clone().add(2, "months");

  const diffMilliseconds = dateTwoMonthsAfter.diff(momentDate);

  const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

  return diffDays;
};
