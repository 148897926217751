import { api } from "@enerbit/base";

const getAssigneeByOrderID = async (orderId: string) => {
	const { data } = await api.get(`/service-orders/orders/${orderId}`);
	const id = data.order_entities[0].assignee_id;
	return id;
};

export const getAddressSMB = async (orderId: string) => {
	const smartBitId = await getAssigneeByOrderID(orderId);
	const { data } = await api.get(
		`/assignees/smartbits?device_id=${smartBitId}`,
	);
	const itemWithLocation = data.items.find(
		(item: { location: { address: string } }) => item.location.address,
	);
	if (itemWithLocation) {
		return itemWithLocation.location.address;
	}
	return "No registra";
};
