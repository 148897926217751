import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Box,
  Typography,
  styled,
  //@ts-ignore
} from "@enerbit/base";
import { useState } from "react";
import { listReports } from "../../const/reportJson";
import { HistoryReadings } from "./components/report/HistoryReadings";
import { ReportInstallation } from "./components/report/ReportInstallation";
import ReportProgramExecute from "./components/report/ReportProgramExecute";
import FrontierControlReports from "./components/report/FrontierControlReports";
import MaterialReports from "./components/report/MaterialReports";
import MaterialDiagnosticReport from "./components/report/MaterialDiagnosticReport";

export const Reports = () => {
  const [optionSelected, setOptionSelected] = useState("");

  const optionList = {
    reporteInstalacion: <ReportInstallation option={optionSelected} />,
    reporteOrdenesProgramadasEjecutadas: <ReportProgramExecute option={optionSelected} />,
    historicoLecturas: <HistoryReadings option={optionSelected} />,
    frontierControlReports: <FrontierControlReports option={optionSelected} />,
    materialReports: <MaterialReports option={optionSelected} />,
    materialDiagnostics: <MaterialDiagnosticReport option={optionSelected} />,
    materialDiagnosticsSolar: <MaterialDiagnosticReport option={optionSelected} isSolar={true} />,
  };

  return (
    <Box sx={{ py: "40px", px: "50px" }}>
      <Typography color='primary' sx={{ fontSize: "28px", fontWeight: 400, margin: "0px" }}>
        Reportes
      </Typography>
      <FormContainer grid_columns={1}>
        <FormControl fullWidth sx={{ my: 3 }}>
          <InputLabel id='demo-multiple-name-label'>Cuál reporte necesitas</InputLabel>
          <Select
            defaultValue=''
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            sx={{ borderRadius: "12px" }}
            input={<OutlinedInput label='Selecciona tipo de documento' />}
            onChange={
              (({ target }: React.ChangeEvent<HTMLInputElement>) =>
                setOptionSelected(target.value)) as any
            }>
            {listReports.map((option, index: number) => (
              <MenuItem key={index} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormContainer>

      <Typography color='primary' sx={{ fontSize: "22px", fontWeight: 400, margin: "10px 0" }}>
        {listReports?.map((item) => (item.value === optionSelected ? item.text : null))}
      </Typography>

      {optionList[optionSelected]}
    </Box>
  );
};

type Grid = {
  grid_columns: number;
};

const FormContainer = styled("div")(({ grid_columns }: Grid) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${grid_columns}, 1fr)`,
  columnGap: "1rem",
  "@media (max-width: 768px)": {
    display: "block",
  },
}));
