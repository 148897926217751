import type { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import EventSource from "react-native-sse";
import { EventSourceListener } from "react-native-sse";
import { MaterialItems } from "../models/materialReports";
import type { ScheduledVsExecutedItem } from "../models/scheduledVsExecuted";
import { updateDownloadProgress } from "../store/slice/reports/reportsSlice";
import { getAuthInfo, refreshToken } from "./token";

type TEventType = "scheduled-vs-executed" | "report-materials-used";
interface ScheduledVsExecutedEvent {
	type: TEventType;
	data: string;
}

interface ErrorEvent {
	type: "error";
	xhrStatus?: number;
	message: string;
}

interface ExceptionEvent {
	type: "exception";
	message: string;
	error: EventSourceListener<"error">;
}

interface EndEvent {
	type: "end";
}

type SSEEvent =
	| ScheduledVsExecutedEvent
	| ErrorEvent
	| ExceptionEvent
	| EndEvent;

const baseUrl = process.env.REACT_APP_BASE_URL;

interface Params {
	report_material_used?: string;
	department?: string;
	status?: string;
	since: string;
	until: string;
	state?: string;
}

export const initializeEventSource = async (
	urlES: string,
	eventName: TEventType,
	token: string,
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
	params: Params,
	isClose = false,
) => {
	const url = new URL(`${baseUrl}${urlES}`);
	const { status, since, until, state } = params;
	url.searchParams.append("since", since);
	url.searchParams.append("until", until);
	if (eventName.includes("scheduled")) {
		if (status !== "complete") {
			url.searchParams.append("status", status!);
		}
		if (state !== "Todos los departamentos") {
			url.searchParams.append("state", state!);
		}
	}
	if (eventName.includes("materials")) {
		url.searchParams.append(
			"report_material_used",
			params.report_material_used!,
		);

		if (params.department) {
			url.searchParams.append("department", params.department);
		}
	}

	let es: EventSource<string>;

	const connectEventSource = (token: string) => {
		es = new EventSource(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	};

	connectEventSource(token);

	let items = [] as ScheduledVsExecutedItem[];

	const itemsPromise = new Promise<ScheduledVsExecutedItem[] | MaterialItems[]>(
		(resolve, reject) => {
			const listener = async (event: SSEEvent) => {
				if (isClose) {
					return () => {
						1;
						es.removeEventListener(
							eventName,
							listener as EventSourceListener<typeof eventName>,
						);
						es.removeEventListener("end", listener);
						es.removeEventListener(
							"error",
							listener as EventSourceListener<"error">,
						);
						es.close();
					};
				}
				if (event.type === eventName) {
					const data = JSON.parse(event.data);
					items = [...items, data.data];
					const totalPages = data.pages;
					const currentPage = data.page;
					const downloadProgress = (currentPage / totalPages) * 100;
					const loadingBody = {
						downloadProgress,
						currentId: data.data.id,
					};
					dispatch(updateDownloadProgress(loadingBody));
				} else if (event.type === "error") {
					if (event.xhrStatus === 401) {
						es.removeEventListener(
							eventName,
							listener as EventSourceListener<typeof eventName>,
						);
						es.removeEventListener("end", listener);
						es.removeEventListener(
							"error",
							listener as EventSourceListener<"error">,
						);
						es.close();
						const newToken = await refreshToken();
						const authInfo = { ...getAuthInfo(), access_token: newToken };
						sessionStorage.setItem("authInfo", JSON.stringify(authInfo));
						connectEventSource(newToken!);

						es.addEventListener(
							eventName,
							listener as EventSourceListener<typeof eventName>,
						);
						es.addEventListener("end", listener);
						es.addEventListener(
							"error",
							listener as EventSourceListener<"error">,
						);
					} else {
						console.error("Connection error:", event.message);
						reject(event.message);
					}
				} else if (event.type === "exception") {
					console.error("Error:", event.message, event.error);
					reject(event.message);
					es.removeEventListener(
						eventName,
						listener as EventSourceListener<typeof eventName>,
					);
					es.removeEventListener("end", listener);
					es.removeEventListener(
						"error",
						listener as EventSourceListener<"error">,
					);
					es.close();
				} else if (event.type === "end") {
					es.removeEventListener(
						eventName,
						listener as EventSourceListener<typeof eventName>,
					);
					es.removeEventListener("end", listener);
					es.removeEventListener(
						"error",
						listener as EventSourceListener<"error">,
					);
					es.close();
					resolve(items);
				}
			};

			es.addEventListener(
				eventName,
				listener as EventSourceListener<typeof eventName>,
			);
			es.addEventListener("end", listener);
			es.addEventListener("error", listener as EventSourceListener<"error">);
		},
	);

	return await itemsPromise;
};
